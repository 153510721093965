// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/PTMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'PTMono';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'PTMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141d2b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#fancy-background { color: var(--background, #141d2b); }


::selection {
  background: var(--primary, #9fef00);
  color: var(--background, #141d2b);
}


svg.rsm-svg {
  background: #2a3240;
  border-radius: 6px;
}

:root {
  --toastify-color-dark: #111927 !important;
  --toastify-color-info: #04e4f4 !important;
  --toastify-color-success: #20e253 !important;
  --toastify-color-warning: #f6f000 !important;
  --toastify-color-error: #f80363 !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAA8D;EAC9D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE;aACW;AACb;AACA,oBAAoB,iCAAiC,EAAE;;;AAGvD;EACE,mCAAmC;EACnC,iCAAiC;AACnC;;;AAGA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,yCAAyC;EACzC,4CAA4C;EAC5C,4CAA4C;EAC5C,0CAA0C;AAC5C","sourcesContent":["@font-face {\n  font-family: 'PTMono';\n  src: url('assets/fonts/PTMono-Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'PTMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #141d2b;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n#fancy-background { color: var(--background, #141d2b); }\n\n\n::selection {\n  background: var(--primary, #9fef00);\n  color: var(--background, #141d2b);\n}\n\n\nsvg.rsm-svg {\n  background: #2a3240;\n  border-radius: 6px;\n}\n\n:root {\n  --toastify-color-dark: #111927 !important;\n  --toastify-color-info: #04e4f4 !important;\n  --toastify-color-success: #20e253 !important;\n  --toastify-color-warning: #f6f000 !important;\n  --toastify-color-error: #f80363 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
